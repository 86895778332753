import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';

/**
 * Escape the given selector such that it can be used as a query selector
 *
 * @param selector
 */
export function getEscapedSelector(selector) {
  return selector.replace(/(:|\.|\[|\])/g, '\\$1');
}

/**
 * Return the value of the given attribute on the element with the given id
 *
 * @param elementId the id of the element to get
 * @param elementAttribute the name of the attribute with the data to return
 */
export function getDataValueFromElement(elementId, elementAttribute) {
  const element = document.getElementById(elementId);
  return element?.getAttribute(elementAttribute);
}

/**
 * Return the json value found by decoding from base64 the value found of the given
 * attribute on the given id. If the attribute doesn't exist or doesn't have any data
 * return the given default
 *
 * The data that was base64 encoded was a byte array, so decoding it will give us a
 * byte array which we need to convert to a string before we can parse it into JSON.
 *
 * @param elementID the id of the element to get
 * @param elementAttribute the name of the attribute with the data to decode
 * @param defaultValue the value to return if the attribute has no value
 */
export const getDecodedBase64FromElementAttribute = (
  elementID,
  elementAttribute,
  defaultValue,
) => {
  const encodedData = getDataValueFromElement(elementID, elementAttribute);

  if (encodedData) {
    return encodedDataToJson(encodedData);
  }

  return defaultValue;
};

/**
 * Convert the given encodedData to a JSON object
 *
 * @param encodedData
 *
 */

export const encodedDataToJson = (encodedData) => {
  const jsonBytes = atob(encodedData);
  const jsonString = byteStringToString(jsonBytes);
  return JSON.parse(jsonString);
};

/**
 * Convert the given byteString to a utf-8 decoded string
 * Based on code from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
 *
 * @param byteString
 */
export const byteStringToString = (byteString) => {
  let buf = new ArrayBuffer(byteString.length);
  let bufView = new Uint8Array(buf);
  for (var i = 0, strLen = byteString.length; i < strLen; i++) {
    bufView[i] = byteString.charCodeAt(i);
  }

  const utf8decoder = new TextDecoder('utf-8');
  return utf8decoder.decode(buf);
};

/**
 * Return a boolean value from the given attribute on the element with the given id
 *
 * @param elementId the ID of the element to get
 * @param elementAttribute the name of the attribute with the data to convert to a bool
 */
export function getDataValueAsBoolFromElement(elementId, elementAttribute) {
  const string = getDataValueFromElement(elementId, elementAttribute);

  if (string) {
    return JSON.parse(string.toLowerCase());
  }

  return false;
}

/**
 * Return the base URL for the current request
 *
 * https://app.searchpilot.com/foo/bar -> https://app.searchpilot.com
 *
 */
export function getBaseURL() {
  const fullURL = window.location.href;
  const url = fullURL.split('/');
  const baseURL = `${window.location.protocol}//${url[2]}`;
  return baseURL;
}

export function getURLParams() {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  return searchParams;
}

/**
 * Get the value of the cookie with the given name
 *
 * @param name the name of the cookie to get
 */
export const getCookie = (name: string): string | null => {
  let cookieValue: string | null = null;
  if (document.cookie && document.cookie != '') {
    const cookies: string[] = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) == name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

/**
 * Return whether the given string is valid json or not
 *
 * @param string the string to check
 */
export const isValidJson = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

export const jsonParseSafe = <T>(value: string | null): T | null => {
  try {
    if (value !== null) {
      return JSON.parse(value);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const checkError = (response) => {
  if (response.status >= 200 && response.status <= 299) {
    return response.json();
  } else {
    return response
      .json()
      .then((data) => {
        return data;
      })
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        } else {
          throw Error(`${response.statusText}(${response.status})`);
        }
      });
  }
};

export const getJson = (
  baseURL: string,
  queryParams: Record<string, string>,
) => {
  let url = baseURL;
  if (Object.keys(queryParams).length !== 0) {
    const params = new URLSearchParams(queryParams);
    url += '?' + params.toString();
  }

  return fetch(url).then(checkError);
};

/**
 * DEPRECATED - use postJSON
 */
export const postRequest = (url: string, data: object) => {
  return postJSON(url, data);
};

/**
 * Post the given object as JSON to the given url, ensuring the csrf token is set
 *
 * @param url the url to post the data to
 * @param data the data to convert to json and post
 */
export const postJSON = (url: string, data: object) => {
  const csrftoken = getCookie('csrftoken');
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken || '',
    },
    body: JSON.stringify(data),
  });
};

export const putJSON = (url: string, data: object) => {
  const csrftoken = getCookie('csrftoken');
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken || '',
    },
    body: JSON.stringify(data),
  });
};

export const deleteUrl = (url: string) => {
  const csrftoken = getCookie('csrftoken');
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'X-CSRFToken': csrftoken || '',
    },
  });
};

/**
 * Posts the given form to the given url, ensuring the csrf token is set
 *
 * @param url the url to post to the form data to
 * @param form the form to post
 */
export const postForm = (url: string, form) => {
  const csrftoken = getCookie('csrftoken');
  const formData = new FormData(form);
  return fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRFToken': csrftoken || '',
    },
    body: formData,
  });
};

/**
 * Posts the given form to the given url, ensuring the csrf token is set
 *
 * @param url the url to post to the form data to
 * @param form the form to post
 */
export const postObjectAsForm = (url: string, data) => {
  const csrftoken = getCookie('csrftoken');
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRFToken': csrftoken || '',
    },
    body: formData,
  });
};

/** Make a post request to the given url with no body
 *
 * @param url the url to make the post request to
 */
export const postEmpty = (url: string) => {
  const csrftoken = getCookie('csrftoken');
  return fetch(url, {
    method: 'POST',
    headers: {
      'X-CSRFToken': csrftoken || '',
    },
  });
};

/** Return whether the given object has any keys */
export const hasKeys = (obj: object | null | undefined) => {
  if (obj === null || obj === undefined) {
    return false;
  }
  return Object.keys(obj).length > 0;
};

/** Set the property given by the path array to the given value
 *
 * @param object the object to update
 * @param path and array of keys leading to the key to update
 * @param value the value to set the key to
 *
 * e.g.
 * const obj = {foo: {bar: {baz: ""}}}
 * setNestedProperty(obj, ['foo', 'bar', 'baz'], "bob")
 * obj == {foo: {bar: {baz: "bob"}}}
 *
 * setNestedProperty(obj, ["foo", "bar", "quz"], "also bob")
 * obj == {foo: {bar: {baz: "bob", quz: "also bob"}}}
 */
export const setNestedProperty = (object, path, value) => {
  if (path.length == 0) {
    return;
  }
  const limit = path.length - 1;
  for (let i = 0; i < limit; ++i) {
    const name = path[i];
    object = object[name] || (object[name] = {});
  }

  if (Array.isArray(object) && path[limit] == -1) {
    object.push(value);
    return;
  }

  const name = path[limit];
  object[name] = value;
};

/** Delete the key at the end of the given path
 *
 * @param object the object to update
 * @param path and array of keys leading to the key to update
 *
 * e.g.
 * const obj = {foo: {bar: {baz: "", notbaz: ""}}}
 * deleteNestedProperty(obj, ['foo', 'bar', 'notbaz'])
 * obj == {foo: {bar: {baz: ""}}}
 *
 * setNestedProperty(obj, ["foo", "bar", "quz"], "also bob")
 * obj == {foo: {bar: {baz: "bob", quz: "also bob"}}}
 */
export const deleteNestedProperty = (object, path) => {
  if (path.length == 0) {
    return;
  }

  const limit = path.length - 1;
  for (let i = 0; i < limit; ++i) {
    if (!object.hasOwnProperty(path[i])) {
      return;
    }
    object = object[path[i]];
  }

  if (!object.hasOwnProperty(path[limit])) {
    return;
  }

  if (Array.isArray(object)) {
    object.splice(path[limit], 1);
    return;
  }

  delete object[path[limit]];
};

/** Deep copy the given object
 *
 */
export const deepCopy = (object) => {
  return cloneDeep(object);
};

/** Trim a string
 */
export type TrimMode = 'none' | 'both' | 'left' | 'right';
export const trim = (v: string, trimMode: TrimMode) => {
  switch (trimMode) {
    case 'none':
      return v;
    case 'left':
      return v.trimLeft();
    case 'right':
      return v.trimRight();
    case 'both':
      return v.trim();
  }
};

// hook to debounce a value
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const removePrefix = (v: string, prefix: string): string => {
  return v.slice(prefix.length);
};

export const extractErrors = (errors: object, prefix: string): object => {
  let newErrors = {};
  for (const key in errors) {
    if (key.startsWith(prefix)) {
      const newKey = removePrefix(key, `${prefix}__`);
      newErrors[newKey] = errors[key];
    }
  }
  return newErrors;
};

// taken from https://www.30secondsofcode.org/js/s/escape-html
export const escapeHTML = (str) =>
  str.replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;',
      })[tag] || tag,
  );

export const moveElement = <T>(
  xs: T[],
  source: number,
  destination: number,
): T[] => {
  const newXs = [...xs];
  const [removed] = newXs.splice(source, 1);
  newXs.splice(destination, 0, removed);
  return newXs;
};

export const moveElementToPosition = <T>(
  xs: T[],
  findBy: (element: T) => boolean,
  toPosition: number,
): T[] => {
  const index = xs.findIndex(findBy);
  return moveElement(xs, index, toPosition);
};

export const moveElementUpDown = <T>(
  xs: T[],
  findBy: (element: T) => boolean,
  where: string,
): T[] => {
  const index = xs.findIndex(findBy);
  let toPosition;
  if (where === 'up') {
    toPosition = index - 1;
  } else {
    toPosition = index + 1;
  }
  return moveElement(xs, index, toPosition);
};

// DATE

export const getDateFromYYYYMMDD = (dateString: string): Date => {
  // get a date time that represents midday UTC on the input date
  // we are using midday as a slight hack to avoid timezone issues
  const trimmedDateStr = dateString.substring(0, 10);
  return new Date(trimmedDateStr + 'T12:00:00');
};

export const getDateFromYYYYMMDDHHMMSS = (dateString: string): Date => {
  const [year, month, day, hour, minute, second] = dateString
    .substring(0, 19)
    .split(/\D/); // any non-digit character

  // force date to be in UTC timezone by including the T
  return new Date(
    `${year}-${month}-${day}T${hour || '00'}:${minute || '00'}:${second || '00'}`,
  );
};

// Formatter for "Today" and "Yesterday" etc
const relative = new Intl.RelativeTimeFormat('en-GB', {
  numeric: 'auto',
});

// Formatter for weekdays, e.g. "Monday"
const short = new Intl.DateTimeFormat('en-GB', {
  weekday: 'long',
  timeZone: 'UTC',
});

// Formatter for dates, e.g. "Mon, 31 May 2021"
export const longDateFormatter = new Intl.DateTimeFormat('en-GB', {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  timeZone: 'UTC',
});

export const longDateTimeFormatter = new Intl.DateTimeFormat('en-GB', {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  timeZone: 'UTC',
  hour: '2-digit',
  minute: '2-digit',
});

export const formatDate = (date: Date): string => {
  const MILLISECONDS_IN_A_DAY = 86400000;

  const now = new Date().setHours(0, 0, 0, 0);
  const then = new Date(date).setHours(0, 0, 0, 0);
  const days = (then - now) / MILLISECONDS_IN_A_DAY;

  if (days > -6) {
    if (days > -2) {
      return relative.format(days, 'day');
    }
    return short.format(date);
  }
  return longDateFormatter.format(date);
};

export const subtractMonths = (numOfMonths, date = new Date()) => {
  date.setMonth(date.getMonth() - numOfMonths);
  return date;
};

export const deduplicate = (list) => Array.from(new Set(list));

// Get User OS

export const getUserOS = () => {
  let OSName = 'unknown';
  const userAgent = navigator.userAgent;
  const navApp = userAgent.toLowerCase();
  if (navApp.includes('mac')) {
    OSName = 'Mac OS';
  } else if (navApp.includes('win')) {
    OSName = 'Windows';
  } else if (navApp.includes('linux')) {
    OSName = 'Linux';
  }
  return OSName;
};

// Most of our clients are from the America so we choose 'en-US' locale formats
export const prettifyThousands = (num) => {
  return num.toLocaleString('en-US');
};

export const roundTo1DecimalPlace = (value: number) => {
  return Math.round(value * 10) / 10;
};

// replace spaces, dashes and underscores with - and remove special characters.
export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

// data for seo, cro and gbot insights charts start at midday, so this is a frontend stopgap to manipulate the series and intervention date data (minus 12 hrs) until the backend can be updated
export const formatDateInDataSeries = (dataSeries) => {
  dataSeries.map((eachSeries) => {
    eachSeries.data = eachSeries.data.map((data) => {
      const twelveHoursInMilliseconds = 12 * 3600000;
      if (eachSeries.series_type === 'line') {
        let [timeStamp, dataPoint] = data;
        return [timeStamp - twelveHoursInMilliseconds, dataPoint];
      }

      if (eachSeries.series_type === 'arearange') {
        let [timeStamp, dataPointLower, dataPointUpper] = data;
        return [
          timeStamp - twelveHoursInMilliseconds,
          dataPointLower,
          dataPointUpper,
        ];
      }

      return data;
    });
  });

  return dataSeries;
};

export const formatInterventionDate = (date) => {
  const twelveHoursInMilliseconds = 12 * 3600000;
  const formattedInterventionDate = date - twelveHoursInMilliseconds;

  return formattedInterventionDate;
};

// this mimics the default datetime format in django templates
export const formatDjangoDateTime = (date: Date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const time = formatDjangoDateTimeHoursMinutes(date);

  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}, ${time}`;
};

export const formatDjangoDateTimeHoursMinutes = (date: Date) => {
  if (date.getMinutes() === 0 && date.getHours() === 0) {
    return 'midnight';
  } else if (date.getMinutes() === 0 && date.getHours() === 12) {
    return 'noon';
  }

  const amPm = date.getHours() > 11 ? 'p.m.' : 'a.m.';

  const hour = date.getHours() % 12 || date.getHours();

  const minutes =
    date.getMinutes() === 0
      ? ''
      : ':' + String(date.getMinutes()).padStart(2, '0');

  return `${hour}${minutes} ${amPm}`;
};
